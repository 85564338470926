@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,700;1,400&display=swap");
* {
  box-sizing: border-box;
  margin: 0mm;
  padding: 0mm;
  font-family: "Poppins", sans-serif;
}

html {
  font-size: 62.5%;
}

/* 1rem == 10px  */
body {
  background-color: #0553a0;
  color: #eee;
}

.App {
  padding: 30px;
}

h2 {
  font-weight: 400;
}

select {
  border: 3px solid #eee;
  background-color: transparent;
  color: #eee;
  border-radius: 5px;
  padding: 7px 20px 7px 10px;
  margin-top: 20px;
  font-weight: 700;
}
select > option {
  color: #eee;
  background-color: #171717;
  font-weight: 400;
  height: 30px;
}

.qr-reader {
  width: 80vw;
  height: auto;
  margin-top: 30px;
}
.qr-reader > section {
  border-radius: 10px;
}
.qr-reader > section > div {
  box-shadow: rgba(255, 255, 255, 0.5) 0px 0px 0px 5px inset !important;
}